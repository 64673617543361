import signupBannerImagePath from '../img/signup-en.png';
import loginBannerImagePath from '../img/login-en.png';

// Basic constants
const year = new Date().getFullYear();
const urlDopplerLegacy = process.env.REACT_APP_DOPPLER_LEGACY_URL;
const urlReports = process.env.REACT_APP_REPORTS_URL;
const urlSite = `https://www.fromdoppler.com`;
const urlHelp = `https://help.fromdoppler.com/en`;
const urlShopify = process.env.REACT_APP_SHOPIFY_URL;
const urlConversations = process.env.REACT_APP_DOPPLER_CONVERSATIONS_URL;

// Common URLs
const urlHelpAdvancedReports = `${urlHelp}/advanced-reports`;
const urlPrivacy = `${urlSite}/en/legal/privacy`;
const urlPrivacyFromSignup = `${urlPrivacy + window.location.search}`;
const urlPrivacyFromSignup_HTMLEncoded = `${urlPrivacy + window.location.search}`;
const urlPrivacyFromLogin = `${urlPrivacy + window.location.search}`;
const urlPrivacyFromForgot = `${urlPrivacy}`;
const mailtoSupport = `mailto:support@fromdoppler.com`;
const mailtoAdmin = `mailto:administracion@fromdoppler.com`;
const subjectBlockedAccountNoPay = `?subject=Cuenta%20suspendida%20por%20falta%20de%20pago%20-%20Login`;
const subjectCanceledAccountNoPay = `?subject=Cuenta%20cancelada%20por%20falta%20de%20pago%20-%20Login`;
const subjectCanceledAccountOtherReason = `?subject=Cuenta%20cancelada%20-%20Login`;
const subjectBlockedAccountInvalidPassword = `?subject=Cuenta%20bloqueada%20por%20intentos%20fallidos%20-%20Login`;
const urlControlPanel = `${urlDopplerLegacy}/ControlPanel`;
const urlSiteTracking = `${urlControlPanel}/CampaignsPreferences/SiteTrackingSettings`;
const urlSiteFromSignup = `${urlSite}/en/`;
const urlSiteFromLogin = `${urlSite}/en/`;
const urlSiteFromForgot = `${urlSite}/en/`;
const urlControlPanelMain = `${urlControlPanel}/ControlPanel`;
const urlPlanSelection = `/plan-selection-deprecated`;
const urlPlanSelectionNew = `/plan-selection/premium/by-contacts`;
const urlMasterSubscriber = `${urlDopplerLegacy}/Lists/MasterSubscriber/`;
const urlCreateSubscriberList = `${urlDopplerLegacy}/Lists/List`;
const urlDraft = `${urlDopplerLegacy}`;
const urlUpgradePlan = `${urlDopplerLegacy}/ControlPanel/AccountPreferences/UpgradeAccount`;
const urlPreUpgradePlan = `${urlDopplerLegacy}/ControlPanel/AccountPreferences/PreUpgrade`;
const urlDkim = `${urlDopplerLegacy}/ControlPanel/AdvancedPreferences/Dkim`;

const messages_en = {
  addons: {
    carousel: {
      slice_1_description: `Improve your customer experience and generate more leads with <Bold>Web Chatbot, Social Media Chatbot and WhatsApp Marketing.</Bold>`,
      slice_1_title: `Manage communication with your users through a single platform`,

      slice_2_description: `Choose the ideal solution <Bold>to attract more customers and reduce the costs</Bold> of hiring multiple tools.`,
      slice_2_title: `Boost your sales with{br} optimized Landing Pages`,

      slice_3_description: `On-site plan`,
      slice_3_title: `On-site plan`,

      slice_4_description: `Grant permissions to people on your team or another, <Bold>to access the different tools of the platform.</Bold>`,
      slice_4_title: `Optimize your team by assigning roles and collaborations in your account`,
    },
  },
  agencies: {
    feature_access: 'Access to the Client Manager panel to manage your clients’ accounts.',
    feature_admin: 'Administration of user profiles and access permissions for each of your clients.',
    feature_consultancy: 'Exclusive advisory to manage multiple accounts.',
    feature_custom: 'Customization with your own logo.',
    feature_reports: 'Advanced Reports with your Campaign’s performances.',
    features_title: 'What’s on the Plan for Agencies?',
    form_legend: 'Complete this Form and we’ll contact you.',
    label_volume: 'Emails sent monthly:',
    submit: 'Request demo',
    submitted: 'Continue on Doppler',
    subtitle_MD: 'Find out what Email Marketing can do for your Agency with a **customized demo**.',
    success_msg: 'Great! We’ll contact you.',
    title: 'Plan for Agencies',
    volume_0: 'Less than 500k',
    volume_10m: 'More than 10m',
    volume_1m: 'Between 1m and 10m',
    volume_500: 'Between 500k and 1m',
    volume_do_not_know: `I don't know`,
  },
  big_query: {
    add_google_account: `Add Google account.`,
    add_permission_google_account: `Add permission for Google accounts.`,
    free_alt_image: `big query`,
    free_btn_redirect: `Look at plus plans`,
    free_text_data_studio_MD: `[Configure a predesigned dashboard in a few steps](https://datastudio.google.com/overview) and get Doppler’s reports in Google Looker Studio.,
    or access BigQuery through another data visualization tool such as Tableau.`,
    free_text_strong: `Boost your Campaigns with reports in Google Looker Studio, Tableau, Power BI and other Data Analytics tools.`,
    free_text_summary: `Get detailed reports to analyze the performance of your Email & Automation Marketing Campaigns. With this integration you can:`,
    free_title: `Connect Doppler with BigQuery`,
    free_ul_item_filter: `Apply different filters to focus the analysis on one or more strategy's issues.`,
    free_ul_item_insights: `Work from a customizable dashboard with editable views.`,
    free_ul_item_strategy: `Get valuable information for your strategy.`,
    plus_beginning: `Start`,
    plus_big_query: `BigQuery`,
    plus_body_step_one_MD: `Give access to BigQuery. [HELP] (${urlHelp}/integrate-doppler-bigquery#accesses)`,
    plus_body_step_two_MD: `Generate Doppler reports at Google Looker Studio. [HELP](${urlHelp}/integrate-doppler-bigquery#create-reports)`,
    plus_configuration: `Configuration`,
    plus_control_panel: `Control Panel`,
    plus_header_MD: `Get detailed reports to analyze the performance of your Email & Automation Marketing Campaigns.`,
    plus_message_error: `Error saving changes.`,
    plus_message_remember: `Remember to save changes.`,
    plus_message_saved: `Changes saved successfully.`,
    plus_paragraph_two: `Google BigQuery`,
    plus_step_one: `First step:`,
    plus_step_one_paragraph: `Users will access to the information of your Doppler account stored at BigQuery. They’ll receive an email telling about those accesses.`,
    plus_step_one_paragraph_MD: `**Psst!** You can only give access to **Google email addresses**, both Gmail and business domains. `,
    plus_step_two: `Second step:`,
    plus_step_two_paragraph_MD: `Connect Doppler and BigQuery to get detailed reports. Doppler provides a **predesigned dashboard of Google Looker Studio** that you can configure in a few steps.`,
    plus_title: `Connect Doppler with BigQuery`,
    upgrade_plan_url: `${urlUpgradePlan}`,
    url_legacy_control_panel: `${urlControlPanelMain}`,
    url_legacy_doppler: `${urlDopplerLegacy}`,
  },
  buy_process: {
    billing_type_1: `Monthly billing`,
    billing_type_12: `Annual billing`,
    billing_type_3: `Quarterly billing`,
    billing_type_6: `Biannual billing`,
    buy_now_title: `Buy now`,
    chat_plan_title: `Chat plan`,
    continue: 'Continue',
    difference_months_to_pay: 'Difference to pay:',
    discount_for_payment_paid: 'Positive balance:',
    discount_half_yearly: 'Biannual',
    discount_monthly: 'Monthly',
    discount_percentage: `Save {discountPercentage}%`,
    discount_quarterly: 'Quarterly',
    discount_yearly: 'Annual',
    feature_item_chat_plan: `Includes up to <Strong>{units} conversations</Strong>`,
    feature_item_discount_advanced_pay: `Advance payment discount {months}/months`,
    feature_item_discount_monthly: `Discount Promotional Code {months}/months`,
    feature_item_discount_monthly_argentina: `Exclusive Discount Argentina {months}/months`,
    feature_item_extra_credits: `Includes {units} extra credits`,
    'feature_item_monthly-deliveries': `Includes up to <Strong>{units} emails</Strong>`,
    feature_item_onsite_plan: `Includes up to <Strong>{units} prints</Strong>`,
    feature_item_prepaid: `Includes up to <Strong>{units} credits</Strong>`,
    feature_item_subscribers: `Includes up to <Strong>{units} contacts</Strong>`,
    marketing_plan_title: `Marketing plan`,
    min_monthly_plan_price: `<P>From <Strong>US$ {price}*/month</Strong></P>`,
    min_single_plan_price: `<P>From <Strong>US$ {price}*/only</Strong></P>`,
    month_with_plural: `{months, plural, one {# month}other {# months} }`,
    months_to_hire: 'Months to hire:',
    months_to_pay: 'Months to pay:',
    onsite_plan_title: `On-site plan`,
    payment_frequency: `Payment frequency`,
    plan_selection: {
      breadcumb_plan_text: `Marketing plan`,
      breadcumb_plan_url: `${urlPlanSelectionNew}`,
      plan_subtitle_description_MD: `Choose the solution that best suits your Campaign objective. Select your plan, frequency and view your purchase.`,
      plan_title: `Choose your marketing plan`,
      plan_type: 'Type of plan',
      plan_type_monthly_deliveries_info: 'Send a large number of Emails at a low unit cost.',
      plan_type_monthly_deliveries_label: 'Emails',
      plan_type_prepaid_info: 'Send Emails from cumulative credits without expiration.',
      plan_type_prepaid_label: 'Credits',
      plan_type_subscribers_info: 'Send unlimited Emails based on your number of Contacts.',
      plan_type_subscribers_label: 'Contacts',
      plan_type_units: `How many {units} do you want to add to your plan?`,
    },
    promocode: {
      apply_btn: `Apply`,
      discount_for_admin: 'General discount: <Strong>{percentage}</Strong>',
      dropdown_title_close: `Do you have a promotional code?`,
      dropdown_title_open: `Enter your promotional code`,
      extra_credits_label: `Extra credits:`,
      is_valid_to: `For {months} months`,
      label_month: `month`,
      valid_until_label: `Valid until supplies last`,
    },
    shopping_cart: {
      price_without_taxes: `*The price does not include taxes.`,
      promotional_code: `¿Tienes un código promocional?`,
      renewal_description: `Subscription with automatic renewal, you can cancel it whenever you want.`,
      save_percentage: `Save {percentage}`,
      title: `Purchase summary`,
    },
    stepper: {
      conversation_plan_step: 'Plan Conversations',
      email_marketing_plan_step: 'Plan Marketing',
      enjoy_doppler_step: 'Enjoy Doppler',
      finalize_purchase_step: 'Finalize your purchase',
      landings_plan_step: 'Plan Landings',
      onsite_plan_step: 'Plan OnSite',
    },
    subscriptions_title: `Subscriptions`,
    upcoming_bills: {
      chat_plan_subtitle: `Chat Plans`,
      landing_pack_subtitle: `Landing Pages packs`,
      marketing_and_chat_plan_subtitle: `Marketing and Chat Plans`,
      marketing_plan_subtitle: `Marketing Plan`,
      onsite_plan_subtitle: `On-site Plans`,
      title: `Upcoming bills`,
    },
  },
  change_plan: {
    all_of_plan: `Everything from {plan} plus:`,
    ask_demo: 'Ask for a demo',
    banner_exclusive_features_description: ` Do you need <Bold>advisory and exclusive accompaniment, dedicated IPs or extra features</Bold>? We can create a custom Plan for you.`,
    banner_exclusive_features_title: 'Ask for custom features for your business',
    big_data_tooltip: 'Big Data feature',
    calculate_price: 'Estimate Price',
    card_agencies_description: 'Manage multiple accounts from one place.',
    card_agencies_title: 'Agencies',
    card_free_description: 'Send unlimited Campaigns up to 500 Contacts, with no contracts or credit cards.',
    card_free_title: 'FREE',
    card_plus_description: 'Increase the effectiveness of your Campaigns with Big Data. You’ll have custom support!',
    card_plus_title: 'Plus',
    card_standard_description: 'Add features to your strategy choosing the Plan type.',
    card_standard_title: 'Advanced',
    current_plan: 'Current Plan',
    features_HTML_agencies: `
    <star>Access to Client Manager dashboard to manage multiple accounts.</star>
    <star>Setting permission levels and accesses for each account or client.</star>
    <option>Personalization with your own logo.</option>
    <option>Advanced Reports about each client’s Campaigns.</option>
    <option>Exclusive advice to manage the Client Manager.</option>`,
    features_HTML_free: `
    <option>Access to Doppler API.</option>
    <option>Subscription Classic and Pop-up Forms.</option>
    <option>Unlimited classic and social Campaigns.</option>
    <option>Basic Email Automation.</option>
    <option>Real-time Reports.</option>
    <option>Customer Support through multiple channels.</option>
    <option>Integrations with other platforms.</option>
    <option>Emojis and Custom Fields in Subjects.</option>
    <newOption>Best practices in Subjects Guide.</newOption>
    <newOption>More effective Subjects List.</newOption>
    <optionComingSoon>Smart Contacts cleaning.</optionComingSoon>`,
    features_HTML_plus: `
    <star>Up to 3 rewarded personalized Reports.</star>
    <bigData>Integration with BigQuery and Data Analytics tools.</bigData>
    <bigData>Google Looker Studio dashboard 100% customizable.</bigData>
    <bigData>Efficacy prediction in Subjects.</bigData>
    <bigData>Smart Send Out at the best day and time for each Contact.</bigData>
    <newOption>Contact Policy.</newOption>
    <newOption>Stop Sending in progress.</newOption>
    <optionComingSoon>Automatic Segments creation.</optionComingSoon>
    <optionComingSoon>Automatic correction of wrong Email addresses.</optionComingSoon>
    <optionComingSoon>Spam prediction.</optionComingSoon>
    <optionComingSoon>Email Automation of Re Engagement.</optionComingSoon>
    <optionComingSoon>Smart Templates.</optionComingSoon>`,
    features_HTML_standard: `
    <star>Advanced Email Automation for E-commerce.</star>
    <star>On-Site Behaviour Analysis.</star>
    <star>Advanced Reports.</star>
    <star>SMS Campaigns.</star>
    <star>Smart Send Out at the best time for each Contact.</star>
    <option>A/B Test Campaigns.</option>
    <option>Highly personalized Campaigns without Doppler’s signature.</option>
    <optionComingSoon>WhatsApp button for Websites.</optionComingSoon>`,
    features_title_agencies: 'Features of the Plus Plan, and:',
    features_title_plus: 'Features of the Advanced Plan, and:',
    features_title_standard: 'Features of the Free Plan, and:',
    hide_features: 'Hide Features',
    increase_action_monthly_deliveries: 'Increase Deliveries',
    increase_action_prepaid: 'Buy Credits',
    increase_action_subscribers: 'Increase Contacts',
    link_exclusive_features: 'Request',
    new_label: 'New',
    option_coming: 'Comming soon',
    per_month: 'per month',
    recommended: 'Recommended',
    show_features: 'See all features',
    since: 'Since',
    title: 'Plans that go along with each stage of your business',
    until_x_subscribers: `Until {subscribers} Contacts.`,
  },
  chat_selection: {
    banner_for_conversations: `Do you need more Conversations? <Link>Write to us</Link>.`,
    expiration_free_plan_message: '*Remember that when you contract a Conversation plan, the version will automatically end three month trial.',
    how_many_conversations_need_message: 'How many Conversations do you need per month?',
    more_option_tickmark_message: 'More',
    plan_benefits: {
      additional_costs: {
        additional_agent_message: 'Additional agent.',
        additional_channel_message: 'Additional channel.',
        additional_conversation_message: 'Additional conversation.',
        additional_costs_message: 'Additional costs',
        extra_costs_message: 'Extra costs',
        from: 'Since',
        legend_1_message: '*You may purchase additional items from <bold>My Plan</bold> once the values ​​established by your plan have been exceeded.',
      },
      collaborative_care_message: 'Collaborative care',
      default_messages_message: 'Default messages',
      facebook_messenger_chat_message: 'Chat on Facebook Messenger',
      included_all_plans_message: 'Included in all plans',
      included_paid_plans_greater_250_conversations_message: 'Included in pay plans greater than 250 conversations',
      included_paid_plans_message: 'Included in all pay plans',
      instagram_chat_message: 'Chat on Instagram',
      lead_generator_message: 'Leads generation',
      messages_by_conversation_tree_message: 'Messages by Conversation tree',
      messages_tracking_message: 'Message tracking',
      support_message: 'Technical support',
      tags_message: 'Tags',
      title: 'What benefits does each plan include?',
      website_chat_message: 'Chat Web',
      whatsapp_business_api_chat_message: 'Chat on WhatsApp Business API',
      whatsapp_business_api_send_messages_message: 'WhatsApp campaign',
    },
    plan_chat_info: {
      legend: 'Select the number of <bold>Conversations</bold> that your business needs to complement and boost your Marketing Strategy.',
      section_1: {
        legend: 'Manage communication from different channels through a single platform.',
        title: 'Optimized experience',
      },
      section_2: {
        legend: "Communicate with your customers instantly and don't miss sales opportunities.",
        title: 'Omnichannel Strategy',
      },
    },
    selected_plan_chat: {
      add_to_cart_button: 'Add to cart',
      agent_with_plural: `{agents, plural, one {# Agent}other {# Agents} }`,
      channel_with_plural: `{channels, plural, one {# Canal}other {# Canals} }`,
      conversations_plan_message: 'Plan Conversations*',
      includes_until_conversations_message: 'Includes up to {conversations} Conversations',
      month_message: 'month',
      no_chat_plan_selected_message: 'No conversation plan selected',
      remove_from_cart_button: 'Remove from cart',
    },
    title: 'Do you want to add a Chat plan?',
  },
  checkoutProcessForm: {
    billing_information_address: 'Fiscal address',
    billing_information_answer_question: 'Enter the answer to the chosen question:',
    billing_information_choose_question: 'Choose the question you prefer:',
    billing_information_city: 'City',
    billing_information_country: 'Country',
    billing_information_firstname: 'First name',
    billing_information_lastname: 'Last name:',
    billing_information_phone: 'Phone number',
    billing_information_province: 'State / Province:',
    billing_information_same_address: 'Similar to Contact Information.',
    billing_information_title: `Billing Information`,
    billing_information_zip_code: 'Zip code',
    button_back: 'Go back',
    contact_information_address: 'Address',
    contact_information_city: 'City',
    contact_information_company: 'Company',
    contact_information_country: 'Country',
    contact_information_firstname: 'First name',
    contact_information_industry: 'Industry',
    contact_information_lastname: 'Last name',
    contact_information_phone: 'Phone number',
    contact_information_province: 'State / Province',
    contact_information_security_question: 'Security question',
    contact_information_security_response: 'Response',
    contact_information_title: `Contact Information`,
    contact_information_zip_code: 'Zip code',
    discount_half_yearly: '6 months',
    discount_monthly: '1 month',
    discount_quarterly: '3 months',
    discount_subscription_half_yearly: 'You have a Half-yearly renovation type',
    discount_subscription_monthly: 'You have a Monthly renovation type',
    discount_subscription_quarterly: 'You have a Quarterly renovation type',
    discount_subscription_subtitle: 'OFF IN YOUR PRICE PLAN',
    discount_subscription_yearly: 'You have a Yearly renovation type',
    discount_title: 'Renovation type',
    discount_yearly: '12 months',
    edit: 'Edit',
    empty_option_select: `Please select an option`,
    payment_method: {
      applied_promocode_tooltip: 'You already have a promo code applied.',
      automatic_debit: 'Automatic debit',
      availabled_credit_cards_legend: 'Use an international card from <bold>Visa, Mastercard or American Express</bold>.',
      availabled_credit_cards_legend_alt_text: 'International credit card: Visa, Mastercard and American Express',
      bank_account: 'Last 4 digits of your account',
      bank_name: 'Bank name',
      business_name: 'Business name',
      cbu: 'CBU',
      cfdi: 'Use of CFDI',
      considerations: 'Consideration:',
      considerations_automatic_debit_note_1: `The invoice for your subscription to Doppler will be automatically debited from your bank account between the 2nd and 5th of each month,
        allowing you to have control over payments directly from your bank statement.`,
      considerations_credit_card_note_1: '<bold>Invoices in USD will be issued from United States</bold>, where is located our subsidiary Doppler LLC.',
      considerations_credit_card_note_2: 'The <bold>amount payable</bold> is similar to the amount of the purchased Plan, and <bold>it depends on applicable taxes</bold> in each country.',
      considerations_mercado_pago_note_1:
        'Invoices will be issued to <bold>Consumidor Final</bold> in <bold>Argentinian pesos</bold>, according to the Banco de la Nación Argentina selling exchange rate  on the prior day.',
      considerations_mercado_pago_note_2: `We'll process the payment monthly through the Mercado Pago platform.`,
      considerations_mercado_pago_note_3: 'Charges will appear on your credit card statement as <bold>MERPAGO*DOPPLER.</bold>',
      consumer_type: 'Consumer',
      credit_card: 'Card number',
      credit_card_option: 'Credit card',
      dni: 'DNI',
      error: 'Ouch! This Credit Card doesn’t allow charges by Doppler LLC. Please contact the issuing company to authorize them.',
      expiration_date: 'Expiration date',
      first_data_error: {
        card_volume_exceeded: 'Ouch! You exceeded the daily limit of attempts with your card. Please wait 24 hours to try again or choose another card.',
        declined: 'Ouch! Transaction not approved. Contact your card issuer.',
        doNotHonor: 'Ouch! Transaction not approved. Contact your card issuer.',
        insufficient_funds: 'Ouch! Credit card with insufficient funds. Try with another credit card.',
        invalid_credit_card_number: 'Ouch! Invalid credit card. Please try again or try with another credit card.',
        invalid_expiration_date: 'Ouch! Invalid credit card. Please try again or try with another credit card.',
        suspected_fraud: 'Ouch! Transaction not approved. Contact your card issuer.',
      },
      first_last_name: 'First name and Last name',
      holder_name: 'First and last name',
      identification_type_colombia: 'NIT',
      mercado_pago: 'Mercado Pago',
      mercado_pago_error: {
        invalid_security_code: 'Ouch! Invalid credit card. Please try again or try with another credit card.',
      },
      payment_way: 'Payment way',
      placeholder_holder_name: 'your name here',
      responsable_iva: 'IVA Responsable',
      responsable_iva_no: 'No',
      responsable_iva_yes: 'Yes',
      security_code: 'Security code',
      tax_certificate: 'Proof of tax situation',
      tax_regime: 'Tax regime',
      title: 'Payment Method',
      transfer: 'Bank transfer',
      valid_thru: 'valid thru',
      warning_mex_transfer: 'The data you enter must match the information that appears on your proof of tax situation.',
      withholding_agent: 'I am not a withholding agent',
    },
    purchase_summary: {
      add_recipient_placeholder: 'Add recipient',
      buy_button: 'BUY',
      credits_for_promocode: 'Extra Credits:',
      difference_months_to_pay: 'Difference of months to pay:',
      discount_for_admin: 'General discount:',
      discount_for_payment_paid: 'Positive balance:',
      discount_for_prepayment: 'Discount for prepayment:',
      discount_for_promocode: 'Discount code:',
      edit_add_recipients_button: 'Edit or add recipients',
      edit_add_recipients_confirmation_button: 'Confirm edit',
      error_message: 'We couldn’t process the transaction. Choose another payment method or try it later.',
      error_only_supports_upselling_message: 'We were unable to process the transaction. This is the Plan you currently have. Modify it to finalize the purchase.',
      explanatory_legend: 'The renewal is automatic and you can cancel it whenever you want. Price Plan may be taxable.',
      explanatory_legend_by_credits: 'Price Plan may be taxable, according to your tax category. You’ll find them detailed on the invoice.',
      header: 'Purchase summary',
      month_with_plural: `{monthsCount, plural, one {# month}other {# months} }`,
      months_to_hire: 'Months to hire:',
      months_to_pay: 'Months to pay:',
      plan_premium_title: 'PREMIUM Plan',
      plan_type_monthly_deliveries: 'by Emails',
      plan_type_monthly_deliveries_label: 'Emails',
      plan_type_prepaid: 'by Credits',
      plan_type_prepaid_label: 'Credits:',
      plan_type_subscribers: 'by Contacts',
      plan_type_subscribers_label: 'Contacts:',
      positive_balance_message: 'You have a credit balance of US$ {positiveBalance} for the unused credits from your last purchase. We will deduct it from your next bills.',
      promocode_error_message: 'Invalid promo code',
      promocode_header: 'Promocode',
      promocode_label: 'Promo code',
      promocode_legend: 'Enter it here',
      promocode_placeholder: 'Enter it here',
      promocode_success_message: 'Promo code applied!',
      promocode_tooltip: 'You already have a discount for prepayment applied.',
      promocode_validate_button: 'Validate',
      secure_payment_message: 'Secure payment',
      send_invoice_email_message: 'We’ll send your billing by email to:',
      success_message: 'Transaction processed successfully!',
      taxes_excluded: '(Taxes not included)',
      to_pay_from_next_month_legend: 'To be paid on your next invoice',
      total: 'TOTAL:',
      transfer_explanatory_legend: 'Price Plan may be taxable, according to your tax category. You’ll find them detailed on the invoice.',
      transfer_explanatory_legend2: 'The renewal is automatic and you can cancel it whenever you want.',
      upgrade_plan_legend: 'On the date the plan change is made, no additional cost will be added, but the total plan will be charged on the first business day of the following month.',
      your_next_billing_legend: 'Your next billing will be for',
    },
    save_continue: 'Save and continue',
    title: `Payment process`,
  },
  checkoutProcessSuccess: {
    chat_plan_acquired_conversations: 'Acquired conversations',
    chat_plan_billing_title: 'Billing',
    chat_plan_default_billing: 'Monthly',
    chat_plan_label: 'Conversations',
    chat_plan_title: 'Your conversations plan',
    chat_plan_type_label: 'Plan Type',
    discount_half_yearly: '6 months',
    discount_monthly: '1 month',
    discount_quarterly: '3 months',
    discount_yearly: '12 months',
    landing_billing_title: 'Renovation',
    landing_packages_title: 'Packages',
    landing_your_landings_pages_plan_title: 'Your Landings Pages plan',
    mercado_pago_check_email_with_invoice_message: `
Within the first 48 business hours, you will receive an email with the payment confirmation then you will be able to start enjoying your new plan.
Payment details will be included in the invoice sent to the recipient you have indicated.`,
    mercado_pago_contact_support: 'If you have doubts regarding the payment status, you can write to us at <Bold>billing@fromdoppler.com</Bold>',
    mercado_pago_error_in_pay: 'If the payment is rejected, we will contact you to help you choose another means of payment.',
    mercado_pago_pay_the_invoice_message: 'The invoice with the payment details will be sent to the recipient you have indicated.',
    mercado_pago_purchase_finished_title: 'Your payment is being processed',
    mercado_pago_steps_title: 'Be sure to read the following information:',
    mercado_pago_warning_message: 'We are processing your payment through Mercado Pago, this may take up to 48 business hours.',
    onsite_plan_acquired_prints: 'Acquired conversations',
    onsite_plan_billing_title: 'Billing',
    onsite_plan_default_billing: 'Monthly',
    onsite_plan_label: 'Prints',
    onsite_plan_title: 'Your on-site plan',
    onsite_plan_type_label: 'Plan Type',
    plan_type: 'Plan Type',
    plan_type_monthly_deliveries: 'Emails by Plan',
    plan_type_monthly_deliveries_availables: 'Available Emails',
    plan_type_monthly_deliveries_label: 'By Emails',
    plan_type_monthly_deliveries_monthly_renovation: 'Monthly',
    plan_type_prepaid: 'Credits by Plan',
    plan_type_prepaid_availables: 'Available Credits',
    plan_type_prepaid_label: 'By Credits',
    plan_type_prepaid_no_expiration: 'No expiration',
    plan_type_prepaid_promocode: 'Gift Credits',
    plan_type_subscribers: 'Contacts by Plan',
    plan_type_subscribers_availables: 'Available Contacts',
    plan_type_subscribers_label: 'By Contacts',
    purchase_finished_title: 'Purchase Finished',
    renewal_type_title: 'Renovation',
    start_using_new_plan_button: 'Start using your new Plan',
    title: `Excellent! You’ve updated your Plan`,
    transfer_check_email_with_invoice_message: 'Check your email, and during the next 24 business hours you’ll receive your invoice with all the information to make the payment.',
    transfer_confirmation_message: 'Once we confirm the payment reception, we’ll tell you by email. You will be able to use your new Plan!',
    transfer_explore_button: 'Explore',
    transfer_explore_message: 'Meanwhile, you can explore your account :)',
    transfer_pay_the_invoice_message: 'Make the payment.',
    transfer_purchase_finished_title: 'Your invoice is on the way',
    transfer_send_the_receipt_message: 'Send the payment proof by email to <Bold>billing@fromdoppler.com</Bold>, so we can activate your new Plan.',
    transfer_send_the_receipt_with_not_upgrade_pending_message: 'Send the payment proof by email to <Bold>billing@fromdoppler.com</Bold>, so we can post it.',
    transfer_steps_title: 'Follow these steps to complete the payment process:',
    transfer_title: 'Well done! You’ve asked for an update of your Plan',
    transfer_warning_message: 'You need to complete the payment process in order to use your new Plan.',
    unlimited_title: 'Unlimited',
  },
  cloud_tags: {
    tag_already_exist: `The tag {tagName} already exist.`,
    tag_limit_exceeded: `Can only add {max} tags.`,
  },
  collaborator_edition: {
    confirm_password: 'Confirm password',
    current_password: 'Current password',
    new_password: 'New password',
  },
  collaborators: {
    add_collaborator: 'New Collaborator',
    edition_subtitle: 'Edit your data to keep using your account. Do not forget to save before leaving!',
    form_modal: {
      description: `The collaborator will have access to manage your Doppler account. It will be able to send campaigns,
      manage lists, edit automations and any other feature.`,
      email: 'Email',
      email_placeholder: 'owner_user@example.com',
      success_subtitle: `Your Collaborator will receive the invitation in their email box.
    Once accepted the invitation, it will be able to manage your account.`,
      success_title: 'Collaborator successfully added',
    },
    menu: {
      disable: 'Inactivate Collaborador',
      edit: 'Edit permissions',
      invite: 'Resend invitation',
    },
    meta_title: 'Doppler | Profile Configuration',
    subtitle: 'From this section you can assign collaborators to help you manage your account.',
    table: {
      email: 'Email',
      firstname: 'Name',
      invitation_date: 'Invitation date',
      lastname: 'Lastname',
      status: 'Status',
      statusType: {
        APPROVED: 'Approved',
        CANCELED: 'Cancelled',
        EXPIRED: 'Expired',
        PENDING: 'Pending',
        REJECTED: 'Rejected',
      },
    },
    title: 'Profile Configuration',
    title_second: 'Your account collaborators',
  },
  common: {
    accept: `Aceptar`,
    advanced_preferences: `Integrations and Advanced Preferences`,
    back: `Back`,
    breadcrumb_plans: 'Plans',
    breadcrumb_plans_url: `${urlPlanSelection}`,
    cancel: `Cancel`,
    connect: `Connect`,
    control_panel: `Control Panel`,
    control_panel_section_url: `${urlControlPanel}`,
    control_panel_url: `${urlControlPanelMain}`,
    conversations_index_url: `${urlConversations}/external-login`,
    copyright_MD: `© ${year} Doppler LLC. All rights reserved. [Privacy Policy & Legals](${urlPrivacy}).`,
    emails: 'Emails',
    empty_data: `Your domain has no data to show in this section yet.`,
    feature_no_available: `The feature is not available yet`,
    finish: 'Finish',
    hide: `Hide`,
    homeUrl: `${urlDraft}`,
    hours_abbreviation: 'hs',
    integrations: `Integrations`,
    message: `Message`,
    message_last_plan: `Tell us more about your needs and we’ll create a perfect Plan for you`,
    message_success: `Done! Your request has been sent`,
    new: `New`,
    next: `Next`,
    optional_message: `Message (Optional)`,
    recaptcha_legal_MD: `
Site protected by reCAPTCHA and the Google [Privacy Policy](https://policies.google.com/privacy?hl=en) and [Terms of Service](https://policies.google.com/terms?hl=en).`,
    save: `Save`,
    send: `Send`,
    show: `Show`,
    something_wrong: `Ouch! An unexpected error occurred`,
    synchronizing: `Synchronizing`,
    ui_library_image: `${process.env.PUBLIC_URL}/images/{imageUrl}`,
    unexpected_error: `Ouch! An unexpected error occurred, please try again`,
    unsaved_changes_message: `Changes that you made may not be saved.`,
  },
  contact_policy: {
    amount_description: 'Send up to',
    error_invalid_range_msg_MD: 'You can set up to **999 Emails and 30 days** maximum.',
    exclude_list_description_MD: `
You can select **up to 10 Lists** to exclude them from the limit of Emails. Contacts of excluded Lists **will receive all your Campaigns.**`,
    exclude_list_selector: {
      description_MD: `Contacts of excluded Lists will receive all your Campaigns. **Remember you can select up to {maxToSelect} Lists.**`,
      max_limit_exceeded: 'You’ve already select the maximum of Lists you can exclude from the Contact Policy.',
      title: 'Exclude Lists from the Contact Policy',
    },
    exclude_list_title: `Exclude Lists`,
    interval_description: 'within',
    interval_unit: 'days',
    meta_title: 'Doppler | Contact Policy',
    promotional: {
      action_text: 'Look at PLUS Plans',
      description_MD: 'Hiring a Plus Plan you’ll get this feature to set a **maximum number of Emails** your Contacts will receive from you in a period of time. You can also:',
      features: {
        exclude_campaigns_MD: '**Exclude Campaigns** from your Contact Policy.',
        exclude_lists_MD: '**Exclude Lists** from your Contact Policy.',
      },
      paragraph: "Optimize your Campaigns' results by defining a Contact Policy according to your strategy.",
      upgrade_plan_url: `${urlUpgradePlan}`,
    },
    select_lists: 'Select Lists',
    shipments_quantity: {
      subtitle_MD: `
Set a **maximum number of Emails** your Contacts will be able to receive from you in a period of time.

Want to know more? Press [HELP](${urlHelp}/contact-policy).`,
      title: 'Quantity of shipments',
    },
    success_msg: 'Changes have been saved.',
    time_restriction: {
      error_invalid_range_of_hours_msg: 'You can set up the hour **from 0 to 23**.',
      hour_from_aria_label: 'Hour from',
      hour_to_aria_label: 'Hour to',
      legend: 'Configure and set a range of shipments for your Campaigns. Choose when you want the shipment to be executed:',
      time_slot_hour_from_label: 'From',
      time_slot_hour_to_label: 'To',
      time_slot_toggle_text: "Only in a specific time slot considering your account's time zone.",
      title: 'Time restriction',
      weekdays_toggle_text: 'Only from Monday to Friday',
    },
    title: 'Contact Policy',
    toggle_text: 'Set maximum number of Emails',
    tooltip_max_limit_exceeded: `You’ve already select the maximum of Lists you can exclude from the Contact Policy.`,
  },
  control_panel: {
    account_preferences: {
      account_information_title: 'Account Information',
      account_movements_title: 'Account History',
      billing_information_title: 'Billing Information',
      collaborator_edition_title: 'Edit collaborator data',
      collaborators_title: 'Profiles configuration',
      contact_information_title: 'Contact Details',
      plans_and_specs_title: 'Pricing and Features',
      sms_settings_title: 'SMS Settings',
      title: 'Account Preferences',
    },
    advanced_preferences: {
      api_key: 'Doppler API',
      custom_domain: 'Form Domains',
      domain_key: 'DKIM, SPF & DMARC',
      title: 'Advanced Preferences',
    },
    campaign_preferences: {
      bounce_handling_title: 'Bounce Handling',
      contact_policy_title: 'Contact Policy',
      email_parameter_title: 'URL Parameters',
      footer_and_header_preferences_title: 'Header and Footer',
      site_tracking_title: 'On-Site Tracking & Push Notifications',
      subscribers_scoring_title: 'Subscribers Scoring',
      title: 'Campaign Preferences',
      unopened_mails_config_title: 'Not Opened Preferences',
      unsubscription_link_title: 'Unsubscribe Link',
    },
    social_preferences: {
      auto_publish_title: 'Auto Publish',
      rss_preferences_title: 'RSS Feed',
      social_network_title: 'Social Media Shares',
      title: 'Social Media Preferences',
      viralization_title: 'Viral Options',
    },
    subtitle_MD: "Here you'll find all the **information about your account**, and you can configure your **Campaigns Preferences and domains**.",
    title: 'Control Panel',
  },
  conversations: {
    actionText: 'Try it for free',
    actionText_expired: 'Choose your plan',
    actionUrl: `${urlPreUpgradePlan}?origin=conversations_promo_page`,
    description: 'Increase your sales by 40% with one solution, centralizing all your channels in one platform. Save time and secure every sale!',
    features: {
      decision_tree_MD: '**Decision Tree:** Design the conversational journey that users will have every time they communicate with your brand.',
      social_media_chatbot_MD: '**Social Media Chatbot:** Manage queries coming from Facebook and Instagram on a single dashboard, providing personalized attention.',
      web_chatbot_MD: "**Web Chatbot:** Manage your user's attention in real time and convert your visitors into customers.",
      whatsApp_chatbot_MD: "**WhatsApp Chatbot:** Manage your user's attention in real time and convert your visitors into potential customers.",
      whatsApp_marketing_MD: '**WhatsApp Marketing:** Automate and personalize instant messages and attract a larger audience.',
    },
    paragraph_free_MD: '**Implement Conversations in your Marketing Strategy!** You can try it during your free trial or until you use 200 Conversations.',
    paragraph_free_expired_MD: '**Implement conversations in your Marketing Strategy!** Get a plan according to your needs.',
    paragraph_not_free_MD: '**Implement Conversations in your Marketing Strategy!** You can try it free for 90 days or until you use 200 Conversations.',
    title: 'Doppler Conversations',
  },
  dashboard: {
    campaigns: {
      link_title: 'SEE ALL',
      link_title_url: `${urlDopplerLegacy}/Campaigns/Sent/`,
      overlayMessage: `Here you will find information about the performance of your latest campaigns.`,
      overlayMessageButton: '<Link>Create campaign</Link>',
      section_name: 'My Campaigns',
      totalCampaigns: 'Total sends',
      totalCtr: 'Click Through Rate',
      totalOpen: 'Open Rate',
    },
    contacts: {
      link_title: 'SEE ALL',
      link_title_url: `${urlMasterSubscriber}`,
      overlayMessage: `Here you will find the most relevant data about your contact lists.`,
      overlayMessageButton: '<Link>Add contacts</Link>',
      section_name: 'My Contacts',
      totalContacts: 'Contacts',
      totalNewContacts: 'New Contacts',
      totalRemovedContacts: 'Removed Contacts',
    },
    first_steps: {
      completed_message: `
<Paragraph>Excellent! You have successfully completed the first steps in Doppler. Now you will find some useful shortcuts to the most frequently used functions.</Paragraph>
      `,
      enable_quick_actions: `Activate quick actions`,
      has_campaings_created_description_MD: `
<Paragraph>Choose the type of campaign that suits best for your business and <Bold><Link>send your first campaign</Link></Bold>.</Paragraph>
      `,
      has_campaings_created_title: `Create your first Campaign`,
      has_campaings_created_url: `${urlDopplerLegacy}/Campaigns/BasicInfo`,
      has_campaings_sent_description_MD: `
<Paragraph><Bold><Link>Track the metrics</Link></Bold> for each campaign you have sent to see how they performed.</Paragraph>
      `,
      has_campaings_sent_title: `Review the reports of your campaigns`,
      has_campaings_sent_url: `${urlReports}/SentCampaigns.aspx`,
      has_domains_ready_description_MD: `
<Paragraph><Bold><Link>Configure DKIM, SPF and DMARC</Link></Bold> so that your contacts receive your mailings successfully.</Paragraph>
      `,
      has_domains_ready_title: `Avoid ending up in spam`,
      has_domains_ready_url: `${urlDkim}`,
      has_list_created_description_MD: `<Paragraph><Bold><Link>Create a list</Link></Bold> by adding your contacts, or create a form and get new ones. </Paragraph>`,
      has_list_created_title: `Create your first list`,
      has_list_created_url: `${urlCreateSubscriberList}`,
      section_name: 'First steps in Doppler',
      welcome_description_MD: `Want to boost your business with Email Marketing? Start doing these **4 actions** 👇`,
      welcome_title: 'You’ve created an account. Well done!',
    },
    lastMonth: 'LAST 30 DAYS',
    learn_with_doppler: 'Learn with Doppler',
    meta_title: 'Doppler | Dashboard',

    postListBlog_1_description: 'Use Push Notifications to reach more customers. \nThey are included in your plan!',
    postListBlog_1_link: 'https://help.fromdoppler.com/en/how-to-active-and-use-send-push-notification?origin=destacadotablero',
    postListBlog_1_link_description: 'Learn how to implement them',
    postListBlog_1_title: '#FeaturedFunctionality',

    postListBlog_2_description: 'Convert more visitors into leads with Pop Up Forms, without overspending!',
    postListBlog_2_link: 'https://help.fromdoppler.com/en/how-to-create-a-subscription-form?origin=destacadotablero',
    postListBlog_2_link_description: 'Learn how to implement them',
    postListBlog_2_title: '#FeaturedFunctionality',

    postListHelp_1_description: `Easy-to-use editor, adaptable design, and advanced security. Increase your leads and customers with just a few clicks!`,
    postListHelp_1_link: 'https://help.fromdoppler.com/en/what-is-a-landing-page-and-how-to-create-one?utm_medium=appnotification&utm_source=fromdoppler&utm_campaign=cm-lanzamientolanding-nutricion-jun24',
    postListHelp_1_link_description: 'See how to implement them',
    postListHelp_1_title: 'Create impactful Landing Pages with Doppler 🚀',

    postListHelp_2_description: 'Need advisory and exclusive accompaniment, dedicated IPs or extra features?',
    postListHelp_2_link: 'https://app.fromdoppler.com/email-marketing-exclusive',
    postListHelp_2_link_description: 'Write to us',
    postListHelp_2_title: 'Aditional Services',

    quick_actions: {
      create_form: `Create form`,
      create_form_url: `${urlDopplerLegacy}/Lists/Form/`,
      launch_automation: 'Create automation',
      launch_automation_url: `${urlDopplerLegacy}/Automation/Automation/AutomationApp/`,
      make_campaign: 'Create campaign',
      make_campaign_url: `${urlDopplerLegacy}/Campaigns/BasicInfo`,
      make_contact_list: 'Build contact list',
      make_contact_list_url: urlCreateSubscriberList,
      section_name: 'Quick actions',
      send_push_notification: `Send push notification`,
      send_push_notification_url: `${urlDopplerLegacy}/ControlPanel/CampaignsPreferences/SiteTrackingSettings`,
      send_sms: 'Send bulk SMS',
      send_sms_url: `${urlDopplerLegacy}/Automation/EditorConfig?idTaskType=12`,
    },

    total: 'TOTAL',
    welcome_message: 'Hello ',
    welcome_message_header: `This is your **Dashboard**. Here you’ll find relevant metrics of your account and complementary resources for your strategy. `,
  },
  default_banner_data: {
    background_url: `${process.env.PUBLIC_URL}/images/violet-yellow.png`,
    description: 'Classics and pop-ups with Single or Double Opt-In subscription. You decide how you want them to look, what data to request and where to place them!',
    functionality: 'subscription forms',
    image_url: `${process.env.PUBLIC_URL}/images/login-en.png`,
    title: 'Add new contacts to your Lists using custom Forms',
  },
  empty_notification_text: `You don't have pending notifications.`,
  exclusive_form: {
    advisory: 'Advice and consultancy.',
    custom_onboarding: 'Custom Onboarding',
    custom_reports: 'Custom Reports',
    dedicated_ip: 'Dedicated IPs ',
    demo: 'SEND',
    description: 'Special features designed for the most demanding users. Development of custom functionalities, personalized attention and more.',
    design_layout_emails: 'Design, HTML layout and sending of Emails ',
    development_custom_features: 'Development of custom features',
    features_for_you: 'Functionalities to suit you',
    form_features: 'Select the characteristics that you would be interested in knowing more about:',
    form_hour_contact: 'What time can we call you?',
    form_legend: 'Complete the following plan form',
    form_title: 'Complete the following form and we will contact you shortly.',
    message: 'Tell us what you need',
    meta_description: 'Special features designed for the most demanding users.',
    new: 'New',
    others: 'Others',
    strategic_accompaniment: 'Strategic Accompaniment.',
    success: 'Excellent! We will get in touch shortly',
    testAb: 'A/B Test Campaigns',
    title: 'Aditional servicies',
  },
  faq: {
    answer_1: `**International credit** card Visa, Mastercard or American Express; **bank transfer** from a minimum amount (for Argentina, Mexico and Colombia clients);
    **credit card through Mercado Pago** (for Argentina).`,
    answer_2: `No, **you can unsuscribe whenever you want** and only the current month will be charged. If you need a contract for the provision of the service, we can do it.`,
    answer_3: `**Yes, all Plans are automatically renewed** whether you pay every month or in advance. Renewal takes place on the **first day of the month**.`,
    answer_4: `You could get a **Plan by Emails**, which has a **lower unitary cost per Email** as your Plan grows. If you have any other need, we can estimate a **Plan with additional Services**.`,
    answer_5: `No. **Credits are accumulative and do not expire**. You can use then to send Campaigns and make buybacks whenever you want.`,
    answer_6: `Once you **choose the Plan**, selecting the **Payment Method** you’ll see your **purchase summary** at the right side of the screen. There you can enter your Promo Code,
    if it is valid you’ll see the discount applied to the purchase of the Plan. _**Promo Codes are not accumulative with prepayment discounts_**.`,
    answer_7: `Yes. **Any Plan by Emails, Contacts or Credits** allows to send SMS Campaigns and get Reports about them. Remember that **SMS are not included in the price of the Plan**.`,
    answer_8: `In Plans by Emails, the total number you’ve purchased will be renewed on the first day of the month. **They aren’t accumulative**.`,
    answer_9: `No. Contacts you’ve imported into a free account are a part of the total Contacts of your new Plan.`,
    question_1: `Which are the available payment methods?`,
    question_2: `Is there a minimun lenght of stay to unsuscribe?`,
    question_3: `Plans are renew automatically?`,
    question_4: `I have more than 100.000 Contacts. Which Plan would be better for me?`,
    question_5: `In Plans by Credits, is there a deadline to use them?`,
    question_6: `How do I use a Promotional Code to hire a Plan?`,
    question_7: `Do I need a Paid Plan to do SMS Marketing?`,
    question_8: `What happens if I don’t use all the Email I’ve purchased per month?`,
    question_9: `When I buy a Plan, can I replace the Contacts I’ve imported into a free account?`,
    title: `FAQs about Plans`,
  },
  feature_panel: {
    email_automation: `Email Automation`,
    email_automation_description: `Right person, right message, right time`,
    email_automation_remarks: `Send 100% personalized Emails according to the behavior and interests of your Subscribers. Save time and money!`,
    forms: `subscription forms`,
    forms_description: `Add new contacts to your Lists using custom Forms`,
    forms_remarks: `Classics and pop-ups with Single or Double Opt-In subscription. You decide how you want them to look, what data to request and where to place them!`,
  },
  footer: {
    iso: `ISO Quality Certification 9001:2008`,
  },
  forgot_password: {
    back_login: `Did you remember your Password? Go back to Log in.`,
    back_login_after_forgot: `Back to Log in`,
    blocked_account_MD: `We deactivate your account. Contact us to help you recover it.`,
    blocked_account_MD_link: `Contact support.`,
    button_request: `Request`,
    confirmation_message_MD: `
Check your inbox!

You'll find an Email with steps to follow.`,
    copyright_MD: `© ${year} Doppler LLC. All rights reserved. [Privacy Policy & Legals](${urlPrivacyFromForgot}).`,
    description: `Don't worry! It happens. Enter your Email and we'll be glad to help you.`,
    expired_data: `Your data has expired. Please go back to the Email we sent you to reset your password.`,
    expired_link: `This link no longer works.`,
    image_path: `${loginBannerImagePath}`,
    max_attempts_sec_question: `You entered an incorrect answer multiple times.`,
    max_attempts_sec_question_link: `Reset your password`,
    max_attempts_sec_question_start_new_process: `to start the process again.`,
    pass_reset_ok: `You updated your password.`,
    placeholder_email: `Psst! Is the same Email you used to create your account`,
    recover_password_link: `Recover password.`,
    url_site: `${urlSiteFromForgot}`,
  },
  forms: {
    label_contact_schedule: `What time can we call you?`,
    label_email: `Email: `,
    label_firstname: `Name: `,
    label_lastname: `Last name: `,
    label_phone: `Phone: `,
    placeholder_phone: `9 11 2345-6789`,
  },
  header: {
    availables: 'availables',
    enabled: 'ENABLED',
    help_url: `${urlHelp}`,
    plan_emails: 'Emails',
    plan_prepaid: 'Premium Plan by Credits',
    plan_suscribers: 'Suscribers',
    profile: 'Profile',
    send_mails: 'Send of Emails',
    send_request: 'REQUEST SENT',
    tooltip_last_plan: `We're making a perfect Plan for your business. We'll contact you soon!`,
  },
  integrations: {
    external_integrations: {
      dynamics_link_url: `${urlHelp}/how-to-integrate-dynamics-with-doppler`,
      dynamics_title: 'Microsoft Dynamics 365',
      empretienda_link_url: `${urlHelp}/doppler-functionalities-for-empretienda`,
      empretienda_title: 'Empretienda',
      facebook_title: 'Facebook',
      infor_title: 'Infor',
      leanrpress_title: 'LearnPress',
      payU_title: 'PayU',
      sumaprop_title: 'Sumaprop',
      tiendup_title: 'TiendUp',
      title: 'External Integrations',
      viewed_title: 'Viewed',
      wix_title: 'Wix',
      wizell_title: 'Wizell',
      wordpress_link_url: `${urlHelp}/how-to-integrate-wordpress-forms-with-doppler`,
      wordpress_title: 'Wordpress',
      xintel_title: 'Xintel',
      zeus_title: 'Zeus',
    },
    native_integrations: {
      big_query_title: 'BigQuery',
      bmw_rsp_crm_title: 'RSP CRM',
      easycommerce_title: 'Easycommerce',
      empretienda_title: 'Empretienda',
      google_Analityc_title: 'Google Analytics',
      jumpseller_title: 'Jumpseller',
      magento_title: 'Adobe Commerce',
      mercadolibre_title: 'MercadoLibre',
      mercadoshops_title: 'Mercado Shops',
      mitienda_title: 'MiTienda',
      prestashop_title: 'PrestaShop',
      shopify_title: 'Shopify',
      tiendanube_title: 'Tiendanube',
      title: 'Native Integrations',
      tokko_title: 'Tokko Broker',
      typeform_title: 'Typeform',
      unbounce_title: 'Unbounce',
      vtex_title: 'VTEX',
      woocommerce_title: 'WooCommerce',
      zoho_title: 'Zoho CRM',
    },
    status_alert: 'Connection issues',
    status_connected: 'Connected',
    status_not_connected: 'Not connected',
    title: 'Integrations',
    zapier_integrations: {
      eventbrite: 'Eventbrite',
      go_to_webinar: 'GoToWebinar',
      hubspot: 'HubSpot',
      lead_ads: 'Facebook Lead Ads',
      optin_monster: 'OptinMonster',
      salesforce: 'Salesforce',
      title: 'Zapier Integrations',
      zapier: 'Zapier',
    },
  },
  invoices_list: {
    amount_column: `Amount`,
    balance_column: `Balance`,
    control_panel_account_preferences_url: `${urlControlPanelMain}?section=AccountPreferences`,
    control_panel_billing_information_section: `Billing Information`,
    control_panel_billing_information_url: `${urlControlPanel}/AccountPreferences/BillingInformationSettings`,
    control_panel_section: `Control Panel`,
    creation_date_column: `Creation Date`,
    currency_column: `Currency`,
    date_column: `Date`,
    document_FC: `Invoice`,
    document_NC: `Credit Note`,
    document_number_column: `Document Number`,
    document_type_column: `Document Type`,
    download_msg: `Download`,
    downloads_column: `Downloads`,
    due_date_column: `Due Date`,
    error_msg: `Ouch! An error occurred while trying to upload your invoices.`,
    no_data_msg: `There are no issued invoices to show yet.`,
    no_download_msg: `There is no invoice to download yet.`,
    paid_to_date_column: `Paid`,
    sub_title: `Here is the detail of the invoices we have issued. You may download each one.`,
    title: `Issued Invoices`,
  },
  landing_selection: {
    choose_landings: `Choose how many Landing Pages you need`,
    description: `Expand your online presence and encourage interaction with your brand, creating specific landing pages for your Marketing actions.`,
    description1: `Create responsive Landing Pages and ensure more conversions on all devices. <strong>Multiply your Leads in few steps!</strong>`,
    description2: `Save money and resources with our <strong>Landing Page templates optimized to generate effective conversions.</strong>`,
    modal: {
      close_button: `I DON'T WANT THIS BENEFIT.`,
      description: `Capture more Leads and boost your sales with optimized Landing Pages.`,
      link_to_buy: `Start now`,
      title: `Create Landing Pages`,
    },
    pack_addons_title: `Discover our add-ons`,
    pack_from: `Packs from US$ {price}/month`,
    pack_of_landing_pages_with_plural: `{packs, plural, one {Pack of # Landing Page}other {Pack of # Landings Pages} }`,
    pack_price: `US$ {price}/month`,
    pack_unit_price: `Price: US$ {unitPrice} each one.`,
    remove_landings_label: `Remove landing pages`,
    shopping_cart: {
      pack_of_landing_pages: `{packagesQty} packs of {landingsQty} Landings each`,
      title: `Landing Pages packs`,
    },
    subtitle1: `Increase your Leads`,
    subtitle2: `Maximize your profitability`,
    title: `Boost your sales with Landing Pages`,
    user_messages: {
      legend_after_remove: `Cancellation of this Add-on will take effect immediately and the Landing Pages charge will no longer be included on your next bill.`,
      warning_description: `If you want to reduce your pack, you must first archive your created Landing Pages. `,
      warning_link_title: `SEE MY LANDINGS`,
    },
  },
  loading: `Loading...`,
  login: {
    button_login: `Log In`,
    collaborator_login_description: 'Your email is already registered in Doppler as a collaborator. By creating your own account, you can keep the same access that you have with that role.',
    collaborator_login_title: 'Access Confirmation',
    copyright_MD: `© ${year} Doppler LLC. All rights reserved. [Privacy Policy & Legals](${urlPrivacyFromLogin}).`,
    enter_doppler: `Log In`,
    enter_doppler_sub: `Today is a good day to boost your business with Email, Automation & Data Marketing!`,
    forgot_password: `Forgot your Password?`,
    head_description: `Attract, engage and convert clients using the Email Marketing Automation power. Try out Doppler!`,
    head_title: `Free Email Marketing Automation with no sending limits | Doppler`,
    image_path: `${loginBannerImagePath}`,
    label_user: `Username: `,
    placeholder_email: `Psst! It's your Email`,
    placeholder_password: 'Enter your password',
    signup: `Sign up for free`,
    url_site: `${urlSiteFromLogin}`,
    you_want_create_account: `Don't have an account yet?`,
  },
  master_subscriber: {
    header_title: `Subscriber General Activity Report`,
    page_description: `Through this report you will be able to know the general activity of a specific subscriber`,
    page_title: `Doppler | Subscriber History`,
    search_form: {
      aria_label: `Filters form to search Subscriber History`,
      aria_search_field: `Enter an Email, First or Last Name to search for Subscriber History`,
      search_field_placeholder: `Search for a subscriber by Email, First or Last Name ...`,
      search_form_legend: `Advanced Subscriber History search`,
    },
    table_result: {
      aria_label_email: `Email`,
      aria_label_lastname: `Last name`,
      aria_label_name: `Name`,
      aria_label_score: `Score`,
      aria_label_state: `State`,
      aria_label_table: `Subscriber history result`,
    },
  },
  master_subscriber_current_search: {
    grid_email: `Email`,
    grid_firstname: `First name`,
    grid_lastname: `Last name`,
    grid_ranking: `Ranking`,
    grid_status: `Status`,
  },
  master_subscriber_sent_campaigns: {
    grid_campaign: `Campaign`,
    grid_clicks: `Unique Clicks`,
    grid_delivery: `Delivery Status`,
    grid_subject: `Subject`,
  },
  onsite_promotional: {
    actionText: 'Try it for free',
    actionText_expired: 'Choose your plan',
    actionUrl: `${urlPreUpgradePlan}?origin=conversations_promo_page`,
    description: 'Increase your sales by 40% with one solution, centralizing all your channels in one platform. Save time and secure every sale!',
    features: {
      decision_tree_MD: '**Decision Tree:** Design the conversational journey that users will have every time they communicate with your brand.',
      social_media_chatbot_MD: '**Social Media Chatbot:** Manage queries coming from Facebook and Instagram on a single dashboard, providing personalized attention.',
      web_chatbot_MD: "**Web Chatbot:** Manage your user's attention in real time and convert your visitors into customers.",
      whatsApp_chatbot_MD: "**WhatsApp Chatbot:** Manage your user's attention in real time and convert your visitors into potential customers.",
      whatsApp_marketing_MD: '**WhatsApp Marketing:** Automate and personalize instant messages and attract a larger audience.',
    },
    paragraph_free_MD: '**Implement Conversations in your Marketing Strategy!** You can try it during your free trial or until you use 200 Conversations.',
    paragraph_free_expired_MD: '**Implement conversations in your Marketing Strategy!** Get a plan according to your needs.',
    paragraph_not_free_MD: '**Implement Conversations in your Marketing Strategy!** You can try it free for 90 days or until you use 200 Conversations.',
    title: 'Doppler On-site',
  },
  onsite_selection: {
    banner_for_prints: `Do you need more Prints? <Link>Write to us</Link>.`,
    card: {
      buy_now_button: 'Buy now',
      description: `Capture more Leads and boost your sales with optimized Landing Pages.`,
      more_information_label: 'More information',
      plan_from: `Plan from US$ {price}/month`,
      title: `On-site plan`,
    },
    how_many_prints_need_message: 'How many Prints do you need per month?',
    more_option_tickmark_message: 'More',
    onsite_plan_info: {
      legend: 'Select the number of <bold>Prints</bold> that your business needs to complement and boost your Marketing Strategy.',
      section_1: {
        legend: 'Manage communication from different channels through a single platform.',
        title: 'Optimized experience',
      },
      section_2: {
        legend: "Communicate with your customers instantly and don't miss sales opportunities.",
        title: 'Omnichannel Strategy',
      },
    },
    plan_benefits: {
      additional_costs: {
        additional_costs_message: 'Additional costs',
        additional_print_message: 'Additional print.',
        extra_costs_message: 'Extra costs',
        from: 'Since',
        legend_1_message: '*You may purchase additional items from <bold>My Plan</bold> once the values ​​established by your plan have been exceeded.',
      },
      title: 'What benefits does each plan include?',
    },
    selected_onsite_plan: {
      add_to_cart_button: 'Add to cart',
      includes_until_prints_message: 'Includes up to {prints} Prints',
      month_message: 'month',
      no_onsite_plan_selected_message: 'No on-site plan selected',
      prints_plan_message: 'Plan on-site*',
      remove_from_cart_button: 'Remove from cart',
    },
    title: 'Do you want to add a On-site plan?',
  },
  pagination: {
    go_back_pages: 'Go back 5 pages',
    go_foward_pages: 'Go forward 5 pages',
  },
  plan_calculator: {
    advice_for_subscribers_large_plan: 'Do you need more Contacts? <Link>Write to us</Link>.',
    banner_for_monthly_deliveries: `Do you need more Emails? <Link>Write to us</Link>.`,
    banner_for_prepaid: `Do you need more Credits? We reccomend you to get a <Link>Plan by Emails</Link>.`,
    banner_for_subscribers: `Do you need more Contacts? We reccomend you to get a <Link>Plan by Emails</Link>.`,
    banner_for_unknown: ' ',
    button_back: 'Go back',
    button_purchase: 'Continue',
    button_purchase_tooltip: 'This is your current Plan! Change it to continue.',
    cost_per_email: 'Cost per email out of plan',
    current_subscription: 'You currently have a subscription for {period} months',
    discount_clarification: `The renewal is automatic and you can cancel it whenever you want. Plan price doesn’t include taxes.`,
    discount_clarification_prepaid: 'Plan price doesn’t include taxes.',
    discount_half_yearly: '6 months',
    discount_monthly: '1 month',
    discount_quarterly: '3 months',
    discount_subscription_half_yearly: 'You have a Half-yearly subscription',
    discount_subscription_monthly: 'You have a Monthly subscription',
    discount_subscription_quarterly: 'You have a Quarterly subscription',
    discount_subscription_subtitle: 'OFF IN YOUR PRICE PLAN',
    discount_subscription_yearly: 'You have a Yearly subscription',
    discount_title: 'Subscription',
    discount_yearly: '12 months',
    per_month: 'by month',
    plan_plus_subtitle: 'Choose the Plan type and estimate its price by sliding the button, according to the number of Contacts or Emails you need.',
    plan_plus_title: 'PLUS Plan',
    plan_premium_subtitle: 'Choose the Plan type and estimate its price by sliding the button, according to the number of Contacts, Emails or Credits you need.',
    plan_premium_title: 'PREMIUM Plan',
    plan_standard_subtitle: 'Choose the Plan type and estimate its price by sliding the button, according to the number of Contacts, Emails or Credits you need. ',
    plan_standard_title: 'ADVANCED Plan',
    plan_type_monthly_deliveries: 'by Emails',
    plan_type_monthly_deliveries_tooltip: 'Perfect Plans for those who send a large number of Campaigns, since they have a lower unit cost per Email.',
    plan_type_prepaid: 'by Credits',
    plan_type_prepaid_tooltip: `Perfect Plans for those who send Campaigns occasionally. Credits can be accumulated and they don’t have a deadline to be used! `,
    plan_type_subscribers: 'by Contacts',
    plan_type_subscribers_tooltip: 'Plans based on number of Contacts, with no sending limits. Paying in advanced you save up to 25%!',
    promocode_error_message: `
We couldn't apply the discount.

Promo code isn’t valid.`,
    subscription_discount: 'You save {discountPercentage}% on the value of the Plan',
    suggestion_for_monthly_deliveries: '¿Do you have more than 10.000.000 schedules? <Link>Contact us.</Link>',
    suggestion_for_prepaid: ' ',
    suggestion_for_subscribers: '¿Do you have more than de 100.000 contacts? We recommend our <Bold>SHIPPING PLANS</Bold>, <Link>contact us.</Link>',
    suggestion_for_unknown: ' ',
    with_half_yearly_discount: 'Payment for 6 months:',
    with_quarterly_discount: 'Payment for 3 months:',
    with_yearly_discount: 'Payment for 12 months:',
  },
  plan_types: {
    actual_plan: 'Actual Plan',
    agency_banner: {
      button_text: 'REQUEST A DEMO',
      description: 'The ideal solution for those who manage multiple accounts. Simplify your management from the Client Manager.',
      title: 'Agency',
    },
    calculate_value_button_label: 'Calculate value',
    contact_plan: {
      comment_card: 'It is the ideal plan if you frequently send your Campaigns at the same amount.',
      description_card: 'Send unlimited Emails based on your number of Contacts.',
      name_card: 'Contacts',
      ribbonText: 'MOST POPULAR',
    },
    credit_plan: {
      comment_card: 'It is the ideal plan to send occasional Campaigns.',
      description_card: 'Send Emails from cumulative credits without expiration.',
      name_card: 'Credits',
    },
    discount_argentina_label: `Exclusive for Argentina`,
    email_plan: {
      comment_card: 'It is the ideal plan if you send numerous Campaigns.',
      description_card: 'Send a large number of Emails at a low unit cost.',
      name_card: 'Emails',
    },
    features_plans: {
      feature_1: 'Classic, social and A/B Test Email Campaigns.',
      feature_2: 'Classic and Pop-up Subscription Forms.',
      feature_3: 'Automation flows.',
      feature_4: 'Push Notifications.',
      feature_5: 'SMS Campaigns.',
    },
    free_plan: {
      comment_card: 'It is the ideal plan if you want to try and discover Doppler.',
      description_card: 'Send unlimited Emails to 500 Contacts for 90 days.',
      name_card: 'Free',
    },
    functionalities_label: 'What can you do with this Plan?',
    functionality_header: {
      comment: 'Learn everything you can do with Doppler!',
      description: 'All our plans include all our functionalities.',
      title: 'Know all the functionalities',
    },
    meta_title: 'Types of plans',
    min_monthly_plan_price: `<Span>Since</Span> <SpanStrike>US$ {priceStrike}/month**</SpanStrike><H3>US$ {price}/month</H3>`,
    min_single_plan_price: `<Span>Since</Span> <H3>US$ {price}</H3>`,
    page_comment: 'All plans include all features.',
    page_description: 'Select the best plan that suits your Email Marketing Strategy.',
    page_title: 'Types of plans',
    payment_methods_banner: {
      title: 'Payment methods',
      transfer_label: 'Bank Transfer',
    },
    see_all_features_label: 'See all features',
    table: {
      automation: {
        description_1: 'Automatically schedule shipments each time a user abandons their purchase and encourage them to complete it.',
        description_2: 'Configure shipments based on the interactions your Contacts have had with your Campaigns.',
        description_3: 'Schedule shipments with content linked to the pages your Contact visited.',
        description_4: 'Add text message sending to your Campaigns.',
        description_5: 'Communicate news from your Blog and information about your brand with scheduled sends to your Contacts.',
        description_6: 'Activate a shipment so that it is received at the right time.',
        description_7: 'Prebuilt flows are templates with various actions already built into them. Save time and adapt them to your liking.',
        description_8: "Convert your Contact's interest into a purchase, from shipments based on the products they have seen in your E-commerce.",
        description_9: 'Send a warm welcome when a person subscribes to your Website.',
        name_1: 'Abandoned Cart',
        name_2: 'Campaign Activity',
        name_3: 'On-Site Tracking',
        name_4: 'SMS Sending',
        name_5: 'RSS Sending',
        name_6: 'Scheduled Dates',
        name_7: 'Prebuilt Flows',
        name_8: 'Visited Product',
        name_9: 'List Subscription',
        title: 'Automation',
      },
      campaings: {
        description_1: 'Check the effectiveness to obtain better results in your Campaigns.',
        description_10: 'Analyze, correct and improve the results obtained in your Campaigns.',
        description_2: 'Create creative and effective Campaigns to connect with your Contacts.',
        description_3: 'Link your Campaigns with your social networks and increase your reach.',
        description_4: 'Customize the content and subject to enhance the effectiveness of your Campaigns.',
        description_5: 'Pause the send, correct what you need and start it again.',
        description_6: 'Schedule your Campaigns on the best day and time for each Contact.',
        description_7: 'Allows your Contacts to be removed from your account and thus stop receiving your Campaigns.',
        description_8: 'Defines how many Campaigns a Contact can receive in a period of time.',
        description_9: 'Program the forwarding of your Campaign to the Contacts that have not opened it or generated bounces.',
        name_1: 'Effective Subjects',
        name_10: 'Reports',
        name_2: 'Classic Campaign',
        name_3: 'Social Campaign',
        name_4: 'Custom Fields',
        name_5: 'Stop Sending in Progress',
        name_6: 'Smart Sending',
        name_7: 'Removal Link',
        name_8: 'Contact Policy',
        name_9: 'Automatic Forwarding',
        title: 'Campaigns',
      },
      editor: {
        description_1: 'Achieve flawless designs with a simple drag and drop.',
        description_2: 'Generate your own images or create effective titles to enhance your communication.',
        description_3: 'Check how your Emails will look on different screens and supports.',
        description_4: 'Design your content from layouts designed to simplify your work.',
        description_5: 'Insert links in images, text or link to your social networks.',
        description_6: 'Includes PayU links to make buying products easier.',
        name_1: 'Attractive Design',
        name_2: 'Artificial Intelligence',
        name_3: 'Preview of your Campaigns',
        name_4: 'Pre-designed Templates',
        name_5: 'Links',
        name_6: 'Payment Button',
        title: 'Editor',
      },
      forms: {
        description_1: 'Design forms that can be viewed on different devices and screens.',
        description_2: 'Capture the attention of your visitors to increase your Database.',
        description_3: 'Ensure the authenticity of your Contacts with a confirmation Email.',
        description_4: 'Set up a timely and effective form on your site.',
        description_5: "Adjust the necessary elements to give your brand's visual identity.",
        description_6: 'Optimize your Form so that it appears in the main search engines.',
        description_7: 'Request your Contacts for the necessary data to send your Campaigns.',
        description_8: 'Add a floating button to capture Contacts and redirect them to WhatsApp.',
        name_1: 'Responsive Forms',
        name_2: 'Classic Form',
        name_3: 'Double Opt-in Form',
        name_4: 'Pop-up Form',
        name_5: 'Custom Forms',
        name_6: 'SEO Forms',
        name_7: 'Simple Opt-in Form',
        name_8: 'WhatsApp Pop-up',
        title: 'Forms',
      },
      integrations: {
        description_1: 'Connect your Online Store with Doppler and maximize your sales.',
        description_10: 'Synchronize the contacts and customers you have in your CRM with Doppler and optimize your sales efforts.',
        description_11: 'Integrate both platforms, synchronize your products and boost your E-commerce sales.',
        description_12: 'Boost your sales effectively by integrating your E-learning with your Doppler account.',
        description_13: 'Set up the board in simple steps and delve into the results of your Campaigns.',
        description_14: 'Connect your Online Store with Doppler and increase your sales.',
        description_15: 'Synchronize your CRM Contacts with Doppler and manage your Marketing Automation actions.',
        description_16: 'Automatically import your products in your Emails and send promotional campaigns.',
        description_17: 'Promote your products and offers by automatically importing them into your Emails.',
        description_18: 'Integrate your account with one of the most used plugins on WordPress sites to capture Leads.',
        description_19: 'Sell your E-commerce products directly from your Email Campaigns thanks to the payment buttons.',
        description_2: 'Connect and analyze the performance of your Campaigns with detailed and customizable Reports.',
        description_20: 'Connect with Doppler through BigQuery and view advanced reports on your Campaigns.',
        description_21: 'Automatically import your Store contacts to Doppler and send them Promotional Campaigns for your business.',
        description_22: 'Bring the contacts you have in Salesforce to Doppler automatically.',
        description_23: 'Import the contacts of your Online Store in Doppler to generate more and better sales.',
        description_24: 'Connect with Doppler through BigQuery and visualize your data interactively.',
        description_25: 'Synchronize your Doppler account and your Online Store to send Campaigns and recover Abandoned Carts from your visitors.',
        description_26: 'Manage your Campaigns and send personalized communications to your E-commerce Contacts.',
        description_27: 'Import your Tokko contacts into Doppler and send them Campaigns with properties of their interest.',
        description_28: 'Synchronize the responses of your surveys and polls to do personalized Email Marketing with Doppler.',
        description_29: 'Grow your Database and your conversions by integrating Campaigns and Landing Pages.',
        description_3: 'Synchronize your accounts to get the most from both platforms.',
        description_30: 'Import the contacts of your Store in Doppler and multiply your sales thanks to Email and Automation Marketing.',
        description_31: 'Add self-reproducing videos to your Email Campaigns to capture the attention of your Contacts.',
        description_32: 'Collect valuable information from your website visitors with Doppler Forms.',
        description_33: 'Send your contacts and clients from your Wizell account to Doppler automatically.',
        description_34: 'Create Subscription Forms to include in your Blog and feed your Doppler Lists.',
        description_35: 'Boost your sales effectively by integrating your E-commerce with your Doppler account.',
        description_36: 'Automatically synchronize your Lists with the contacts you have in your real estate CRM.',
        description_37: 'Integrate Doppler with your favorite applications without spending hours programming.',
        description_38: 'Integrate all Zoho web apps with Doppler and strengthen communication with your prospects.',
        description_4: 'Take advantage of the best Doppler features to boost your E-commerce.',
        description_5: 'Unite the power of Doppler with this app to organize and manage events.',
        description_6: 'Send Campaigns to your Contacts generated through ads on this social network.',
        description_7: 'Find out in real time how your Contacts behave on your website when they arrive from your Campaigns.',
        description_8: 'Send Campaigns to your Contacts generated through this online conference platform.',
        description_9: 'Synchronize your account with Doppler and convert your prospects into customers through your Email Campaigns.',
        name_1: 'Adobe Commerce',
        name_10: 'Infor',
        name_11: 'Jumpseller',
        name_12: 'LearnPress',
        name_13: 'Looker Studio',
        name_14: 'MiTienda',
        name_15: 'Microsoft Dynamics 365',
        name_16: 'Mercado Shops',
        name_17: 'Mercado Libre',
        name_18: 'OptinMonster',
        name_19: 'PayU',
        name_2: 'BigQuery',
        name_20: 'Power BI',
        name_21: 'PrestaShop',
        name_22: 'Salesforce',
        name_23: 'Shopify',
        name_24: 'Tableau',
        name_25: 'Tiendanube',
        name_26: 'Tiendup',
        name_27: 'Tokko',
        name_28: 'Typeform',
        name_29: 'Unbounce',
        name_3: 'Easycommerce',
        name_30: 'VTEX',
        name_31: 'Viewed',
        name_32: 'WIX',
        name_33: 'Wizell',
        name_34: 'WordPress',
        name_35: 'WooCommerce',
        name_36: 'Xintel',
        name_37: 'Zapier',
        name_38: 'Zoho',
        name_4: 'Empretienda',
        name_5: 'Eventbrite',
        name_6: 'Facebook Lead Ads',
        name_7: 'Google Analytics',
        name_8: 'GoToWebinar',
        name_9: 'Hubspot',
        title: 'Integrations',
      },
      omni: {
        description_1: 'Send invoices, vouchers, account confirmations and secure and effective communications to your clients. Service with extra costs.',
        description_2: 'Send alerts or relevant information to the visitors of your Website or Online Store.',
        description_3: 'Add text message delivery to your Campaigns. Service with extra costs.',
        name_1: 'Transactional Email',
        name_2: 'Push Notifications',
        name_3: 'SMS',
        title: 'Omnichannel',
      },
      report: {
        description_1: 'Know the results of your Campaigns in real time.',
        description_2: 'Cross-reference information from different sources and generate Advanced Reports.',
        description_3: 'Synchronize with this tool and know the global metrics.',
        description_4: 'Discover which content was most relevant to the links you included in your Campaign.',
        description_5: 'Know in which geographical areas your Campaigns have the most interactions.',
        description_6: 'Find out the reach of your Emails on social networks.',
        description_7: 'Know the number of unsubscriptions and the reasons why your Contacts have stopped following you.',
        name_1: 'Advanced Reports',
        name_2: 'Integration with BigQuery',
        name_3: 'Google Looker Studio',
        name_4: 'Heat Map',
        name_5: 'Geolocation',
        name_6: 'Social Impact',
        name_7: 'Removals',
        title: 'Reports',
      },
      segmentation: {
        description_1: 'Add your Contacts manually, from a file or through the capture or subscription Form on your site.',
        description_2: 'Create segments based on relevant data for your Campaigns.',
        description_3: 'Label your links and create Segments based on the clicks they make on your Emails.',
        description_4: 'Identify those contacts most interested in your business, and reactivate those who are losing interest.',
        description_5: 'Identify your customers through their buying behavior and send personalized communications.',
        name_1: 'Import Contacts',
        name_2: 'Demographic Segmentation',
        name_3: 'Link Segmentation',
        name_4: 'Segmentation by activity',
        name_5: 'Behavioral Targeting (RFM)',
        title: 'Segmentation',
      },
    },
  },
  plans: {
    monthly_deliveries_amount_description: 'Emails',
    prepaid_amount_description: 'Créditos',
    subscribers_amount_description: 'Contactos',
    unknown_amount_description: ' ',
  },
  promotional_ribbons: {
    coming_soon: 'Coming soon',
    new: 'New',
  },
  reports: {
    datahub_not_domains_title: `Add your web domain and analyze the behavior of your users`,
    no_domains_MD: `
Register the domain (s) you want to track and access to detailed Reports. Discover which are the
most visited pages of your Website or E-commerce, how many visitors have been identified by
Doppler and how many have not. Any doubts? Press [HELP](${urlHelpAdvancedReports}).`,
    no_domains_button: `Add your domain`,
    no_domains_button_destination: `${urlSiteTracking}`,
    promotional: {
      description_MD: 'Get **detailed Reports** to understand the behaviour of your Website or E-commerce visitants. Discover:',
      features: {
        feature_1: 'Which are the most visited pages.',
        feature_2: 'How many visitants are already into your Contacts Lists.',
        feature_3: `How many visitants haven't been subscribed to receive your Campaigns yet.`,
      },
      link_text: 'REVIEW PLANS',
      paragraph: `Hiring any Paid Plan you can access to this feature.`,
      title: `Analyze your visitants' behaviour and improve your strategy`,
      upgrade_plan_url: `${urlUpgradePlan}`,
    },
  },
  reports_box: {
    to: `to`,
    visits_description_with_email: `Total number of users who visited your Website and whose Email has been identified by Doppler. If a user entered several times, only one will be counted.`,
    visits_description_without_emails: `Total number of users who visited your Website and whose Email has not been identified by Doppler. If a user entered several times, only one will be counted.`,
    visits_with_email: `Users with Email`,
    visits_without_emails: `Users without Email`,
    without_included: `(not included)`,
  },
  reports_daily_visits: {
    title: `Unique Page views`,
    tooltip_page_views: `Pageviews: `,
    tooltip_with_email: `Users with Email: `,
    tooltip_without_email: `Users without Email: `,
  },
  reports_filters: {
    all_pages: `All pages`,
    description_MD: `
Find out which are the most visited pages, how many of those visitors already have an Email identified
by Doppler and how many don't. By tracking the user's journey you'll be able to detect vanishing points and opportunities for improvement! If you have any doubts, press [HELP](${urlHelpAdvancedReports}).
      `,
    domain: `Domain`,
    domain_not_verified_MD: `Your domain is not verified. It is necessary to start obtaining data about your visits. [VERIFY DOMAIN](${urlSiteTracking}).`,
    no_information: `No information available`,
    pages: `Pages`,
    rank_time: `Time period`,
    title: `Track users behavior, analyze it and optimize your Marketing actions`,
    today: `Today`,
    verified_domain: `Last registered visit:`,
    week_with_plural: `{weeksCount, plural, =0 {no weeks} one {# week}other {# weeks} }`,
  },
  reports_hours_visits: {
    few_visits: '0 to {max}',
    lot_visits: `+{min}`,
    medium_visits: `{min} to {max}`,
    title: `Day of week and hours`,
    users: `Pages views:`,
    users_with_email: `Users with email:`,
    users_without_email: `Users without email:`,
  },
  reports_pageranking: {
    more_results: `Show more results`,
    top_pages: `Most visited pages`,
    total_visits: `Visits`,
    visits_with_email: `User visits with Email`,
    visits_without_email: `User visits without Email`,
  },
  reports_partials_campaigns: {
    campaign_name: `Campaign name: `,
    campaign_state: `Campaign status`,
    campaign_subject: `Subject: `,
    campaign_summary: `Campaign Summary`,
    delivery_rate: `Delivery Rate`,
    emails_delivered: `Emails Delivered:`,
    hard_and_soft: `Hard and Soft bounces`,
    header_description_shipped: `Your Campaign has been sent. These are the final results.`,
    header_description_shipping: `Your Campaign is being sent. These are the partial results so far.`,
    header_title_shipped: `Campaign Report`,
    header_title_shipping: `Progress Report`,
    last_click_date: `Last Click Date:`,
    last_open_date: `Last Open Date:`,
    not_open: `Not Open`,
    opened: `Opened`,
    page_description: `Partial Campaign Reports`,
    page_title: `Doppler | Partial Campaign Reports`,
    shipped: `Sent`,
    shipping: `Sending`,
    total_clicks: `Total Clicks:`,
    total_forwarded: `Total Forwarded:`,
    total_openings: `Total Openings:`,
    total_recipients: `Total recipients`,
    total_sent_so_far: `Emails sent so far`,
    total_subscribers: `Total Subscribers:`,
    total_unsubscribers: `Total Unsubscribers:`,
    unique_clicks: `Unique Clicks:`,
    unique_opens: `Unique Opens:`,
  },
  reports_title: `Doppler | Reports`,
  shopify: {
    admin_apps: `Shopify control panel`,
    admin_apps_url: `https://{shopName}/admin/apps`,
    connect_url: `${urlShopify}/install`,
    entity_buyers: `Buyers`,
    entity_contacts: `Contacts`,
    entity_customers: `Customers`,
    entity_title: `Data Entity Name`,
    error_cannot_access_api: `Oops! We could not connect to Shopify API, please try again later.`,
    header_disconnected_warning: `By pressing "Connect" you will be redirected to Shopify, where you can carry out the necessary steps to integrate.`,
    header_store: `Account name:`,
    header_subtitle_MD: `
Automatically send all your E-commerce Contacts and their purchase data to Doppler's Lists. Also you can import your store products in Email Templates
and create Abandoned Cart and Retargeting Product Automations. Any questions? Press [HELP](${urlHelp}/how-to-integrate-doppler-with-shopify/).`,
    header_synchronization_date: `Last synchronization date:`,
    header_title: `Connect Doppler with your Shopify store`,
    list_subtitle: `You can synchronize the data manually whenever you want.`,
    list_title: `Synchronized Lists`,
    no_list_available: `Waiting for List...`,
    table_list: `List Name`,
    table_shopify_customers_count: `Subscribers`,
    title: `Doppler | Shopify`,
  },
  signup: {
    access_new_account: `With this email you will access your new <strong>Doppler</strong> account.`,
    button_signup: `Create free account`,
    check_email: `Enter `,
    check_email_2: ` to validate it.`,
    check_your_email: `Check your email`,
    copyright_MD: `© ${year} Doppler LLC. All rights reserved. [Privacy Policy & Legals](${urlPrivacyFromSignup}).`,
    copyright_MD_test: `© ${year} Doppler LLC. All rights reserved.`,
    do_you_already_have_an_account: `Already have an account?`,
    email_not_received: `To log in, first activate your account from the email you received from Doppler.`,
    have_doubts: `Do you have doubts? <a>Contact us</a> and we will help you`,
    head_description: `Attract, engage and convert clients using the Email Marketing Automation power. Try out Doppler!`,
    head_title: `Free Email Marketing Automation with no sending limits | Doppler`,
    if_didnt_receive_email: `If you didn’t receive the email, `,
    image_path: `${signupBannerImagePath}`,
    invitation_signup_title: `Invitation acceptance`,
    label_email: `Email: `,
    label_firstname: `First name: `,
    label_lastname: `Last name: `,
    label_password: `Password: `,
    label_phone: `Phone: `,
    legal_MD: `
Doppler informs you that the personal data you provide by completing this form will be treated by Doppler LLC as responsible for this web site.

**Purpose:** Sign you up into our platform and provide the services that you require.

**Legitimation:** Consent of the applicant.

**Recipients:** Your data will be saved by Doppler, Zoho as CRM, Google as the provider of reCAPTCHA service, Digital Ocean, Cogeco Peer1 and Rackspace as hosting companies.

**Additional information:** In Doppler's [Privacy Policy](${urlPrivacyFromSignup_HTMLEncoded})
you'll find additional information about the data storage and use of your
personal information, including information on access, conservation, rectification,
deletion, security, cross-border data transfers and other issues.
  `,
    legal_policy: ` Privacy and legal policy.`,
    legal_tittle: `Read about data protection in Doppler.`,
    log_in: `Log In`,
    no_more_resend_MD: `We have already resent you the validation email. Check if it arrived to your spam folder. Need help?`,
    no_more_resend_MD_link: `Contact us.`,
    placeholder_email: `Enter your email. You must have access`,
    placeholder_first_name: `Enter your first name`,
    placeholder_last_name: `Enter your last name`,
    placeholder_password: `Create your password`,
    placeholder_phone: `9 11 2345-6789`,
    privacy_policy_consent_MD: `I accept the <Link>privacy policy</Link>.`,
    privacy_policy_consent_url: `${urlPrivacyFromSignup_HTMLEncoded}`,
    promotions_consent: `I want to receive news and promotions.`,
    resend_email: `<underline>request resend.</underline>`,
    sign_up_sub: `Create a free account and start now an email campaign to reach your customers.`,
    thanks_for_registering: `Thank you for registering`,
    url_site: `${urlSiteFromSignup}`,
  },
  subscriber: {
    status: {
      active: 'Active',
      inactive: 'Active not Associated to List',
      pending: 'Pending',
      standBy: 'On Hold waiting for Upgrade',
      unsubscribed_by_client: 'Removed by Client',
      unsubscribed_by_hard: 'Removed by Hard-Bounced',
      unsubscribed_by_never_open: 'Removed by Never-Opened',
      unsubscribed_by_soft: 'Removed by Soft-Bounced',
      unsubscribed_by_subscriber: 'Removed by Subscriber',
    },
  },
  subscriber_gdpr: {
    consent: 'Consent:',
    description: 'Here you will find the history of all consents given by your Subscriber.',
    download_permission_history: 'Download full history',
    empty_data: 'This Subscriber has not given or denied any permission.',
    empty_html_text: 'With no legal text defined',
    latest_results: 'Last 10 results',
    modification_date: 'Modification date:',
    modification_source: 'Origin:',
    modification_source_ip: 'Modification source IP:',
    permission_description: 'Custom text',
    permission_name: 'Field Name',
    permission_value: 'Value',
    title: 'Subscriber GDPR permission history',
    value_false: 'Rejected',
    value_none: 'Unanswered',
    value_true: 'Accepted',
  },
  subscriber_history: {
    alt_image: 'Campaign Preview',
    delivery_status: {
      hardBounced: 'Removed by Hard',
      notOpened: 'Not Opened',
      opened: 'Opened',
      softBounced: 'Removed by Soft',
    },
    description: 'Here you will be able to know the Campaign behavior history of your Subscribers.',
    empty_data: `So far there are no Campaigns sent`,
    subscriber_breadcrumb: 'Subscribers',
    subscriber_breadcrumb_url: `${urlMasterSubscriber}`,
    table_result: {
      aria_label_table: `Campaign History result`,
    },
    title: 'Subscriber historical behavior',
    unsubscribed_date: 'Unsubscribed Date:',
  },
  subscriber_list_selector: {
    confirm_selection: 'Confirm Selection',
    error_loading_list: 'Error to show Lists. Close this window and open it again from the "Select Lists" button.',
    max_limit_exceeded: 'You have already selected the maximum number of lists allowed.',
    no_list: {
      create_list: 'Create a list',
      create_list_url: `${urlCreateSubscriberList}`,
      description_MD: `
Own an **updated Database** is a key to the effectiveness of Email & Automation Marketing.
With Doppler you can generate Segments with common interests, create Forms to attract new Contacts and more.`,
      not_now: 'Not now',
      strong_text: 'Create your first List and import Contacts in simple steps!',
      title: 'You don’t have any List yet!',
    },
    table_columns: {
      list_name: 'List name',
      subscribers: 'Subscribers',
    },
  },
  trafficSources: {
    direct: `Direct`,
    email: `Email`,
    organic: `Organic Search`,
    paid: `Paid Search`,
    referral: `Referral`,
    social: `Social`,
    title: `Traffic sources`,
    users_with_email: `Users with email`,
    users_without_email: `Users without email`,
  },
  updatePaymentInformationSuccess: {
    all_invoices_processed_message: 'Your Doppler account has been reactivated.',
    all_invoices_processed_title: 'Done! Your payment has been accepted.',
    all_invoices_processed_with_active_account_message: 'You can continue using your Doppler account.',
    back_button: 'Back',
    credit_card_error: {
      card_volume_exceeded: 'You exceeded the daily limit of attempts with your card.',
      declined: 'Transaction not approved. Contact your card issuer.',
      default: 'We couldn’t process the transaction. Choose another payment method or try it later.',
      doNotHonor: 'Transaction not approved. Contact your card issuer.',
      insufficient_funds: 'Credit card with insufficient funds. Try with another credit card.',
      invalid_credit_card_number: 'Invalid credit card. Please try again or try with another credit card.',
      invalid_expiration_date: 'Invalid credit card. Please try again or try with another credit card.',
      pending: 'Pending',
      suspected_fraud: 'Transaction not approved. Contact your card issuer.',
    },
    enter_button: 'Enter',
    go_to_login_button: 'Login',
    not_all_invoices_processed_legend: 'We will reactivate your Doppler account so you can make the payment.',
    not_all_invoices_processed_message: 'There was at least one overdue payment rejected. We will contact you at {userEmail} to resolve it.',
    not_all_invoices_processed_title: 'There are still overdue payments',
    payment_pending_message_line1: 'We will confirm your payment in less than 48 business hours and will notify you at {userEmail}',
    payment_pending_message_line2: 'We will reactivate your account so you can use Doppler while waiting for the payment to be confirmed.',
    payment_pending_title: 'Your Mercado Pago payment is being processed',
    rejected_payments_legend_1: `Try again with an international <bold>Visa</bold>, <bold>American Express</bold> or <bold>Mastercard.</bold>`,
    rejected_payments_legend_2: `If you need help, please [contact us](${mailtoSupport}).`,
    rejected_payments_message: 'These overdue payments were rejected:',
    rejected_payments_title: `Your payment was declined.`,
    title: 'Reprocess finished',
    transfer_message: 'You will shortly receive the invoice to settle your debt.',
    transfer_title: 'Done! Your payment method has been updated.',
  },
  updatePaymentMethod: {
    button_back: 'Go back',
    payment_method: {
      save_continue_button: 'Save and pay',
      success_message: 'Operation processed successfully!',
      title: 'Payment method',
      transfer: {
        email: 'Email',
        firstname: 'First name',
        lastname: 'Last name',
        note_legend: `Please confirm your contact information so we can send you the details of your overdue invoices and the bank account to make the transfers.
        We will contact you to help you resolve the overdue payments.`,
        phone: 'Phone',
        send_button: 'Send my information',
        send_email_error_message: 'Ouch! The email could not be sent.',
        send_email_success_message: 'The email has been sent successfully!',
        title: 'We need to contact you',
      },
    },
    reprocess: {
      invoices_declined_table: {
        amount_column: 'Amount',
        date_column: 'Invoice date',
        error_column: 'Reason that prevented the payment',
        invoice_column: 'Invoice number',
      },
      payment_now_button: 'Pay all',
      pending_amount_message: 'Total',
      success_message: 'Operation processed successfully!',
      title: 'Overdue payments',
    },
    title: `Enter a new payment method to pay your overdue invoices.`,
  },
  upgradePlanForm: {
    confirmation_subtitle_contact: `You have just hired a new Monthly Plan to send unlimited Campaigns up to <bold>Contacts.</bold> We’ll send you an email with more details about the new Plan.`,
    confirmation_subtitle_shipping: `You have just hired a new Monthly Plan to send unlimited Campaigns up to <bold>Emails.</bold> We’ll send you an email with more details about the new Plan.`,
    confirmation_title: `Done!`,
    message_placeholder: `Your message`,
    plan_select: `Select Plan`,
    title: `Request an update of your Plan`,
    update: `Update Plan`,
  },
  upgrade_suggestion_form: {
    breadcrumb_increase_plan: 'Increase Plan',
    breadcrumb_maximum_plan: 'Plan Maximum',
    description: `Our sales team will advice you about the most convenient Plan according to your needs.`,
    form_hour_contact: 'At what time can we contact you?',
    form_legend: 'Complete the following plan form',
    form_title: 'Complete the Form and we’ll contact you soon',
    message: `Select the additional services you're interested in`,
    message_placeholder: 'Your message',
    meta_description: 'Form to suggest plan upgrade.',
    submit_button: 'Send',
    success: 'Excellent! We will get in touch shortly',
    title: 'Ask for a bigger Plan',
  },
  validate_max_subscribers_form: {
    button_accept: 'Accept',
    checkbox_empty: 'Ouch! You must select at least one option.',
    form_help: '* Want to know why we are asking for this info?',
    form_help_link_text: 'Know more here',
    info_text: '** We need to validate the data you provided.This might take a while to process. Thanks for your patience.',
    request_processed: 'Perfect! We are validating the origin of your Subscribers. Please, keep in mind this process might have a little delay.',
    subtitle: `Please, provide us with your contact information so we can complete this action.Complete the following Form so we can
      validate your Subscribers’ origin. It will only take a few minutes. Thank you, we’ll contact you soon!`,
    title: 'Validate your Subscribers’ Origin',
  },
  validation_messages: {
    error_account_contact_zoho_chat: `<button>Chat with the Customer Support team</button> for help.`,
    error_account_has_not_users_associated: `Ouch! This email does not have any associated account, you should contact the administrator.`,
    error_account_is_blocked_disabled_by_cm: `This account was blocked by the administrator. Get in touch for more information: `,
    error_account_is_blocked_invalid_password: `Ouch! This account has been blocked due to failed access attempts.`,
    error_account_is_blocked_invalid_password_contact_support_MD: `Please [contact the Customer Support team](${mailtoAdmin + subjectBlockedAccountInvalidPassword}) for help.`,
    error_account_is_blocked_invalid_password_zoho_chat_msg: `¡Hola! ¿Me ayudas con mi cuenta bloqueada por intentos fallidos?`,
    error_account_is_blocked_not_pay: `The account is suspended because there are overdue payments.`,
    error_account_is_blocked_not_pay_contact_support_MD: `Please [contact the Customer Support team](${mailtoAdmin + subjectBlockedAccountNoPay}) for help.`,
    error_account_is_blocked_not_pay_update_payment_information: `Review overdue payments`,
    error_account_is_blocked_not_pay_zoho_chat_msg: `¡Hola! ¿Me ayudas con mi cuenta suspendida por falta de pago?`,
    error_account_is_canceled_not_pay: `Ouch! This account has been canceled due to non-payment.`,
    error_account_is_canceled_not_pay_contact_support_MD: `Please [contact the Customer Support team](${mailtoAdmin + subjectCanceledAccountNoPay}) for help.`,
    error_account_is_canceled_not_pay_zoho_chat_msg: `¡Hola! ¿Me ayudas con mi cuenta cancelada por falta de pago?`,
    error_account_is_canceled_other_reason: `Ouch! This account has been canceled.`,
    error_account_is_canceled_other_reason_contact_support_MD: `Please [contact the Customer Support team](${mailtoAdmin + subjectCanceledAccountOtherReason}) for help.`,
    error_account_is_canceled_other_reason_zoho_chat_msg: `¡Hola! ¿Me ayudas con mi cuenta cancelada?`,
    error_checkbox_policy: `Ouch! You haven't accepted the Doppler's Privacy Policy.`,
    error_email_already_exists: `Ouch! You already have a Doppler account.`,
    error_expired_invitation_link: `Important announcement! Your collaborator invitation has expired or been disabled, you will need to contact the administrator.`,
    error_has_accents: `Ouch! The Email must not have any accent.`,
    error_invalid_captcha: `Ouch! We couldn't validate you are a human, please reload the page and try again.`,
    error_invalid_card_number: `Ouch! Invalid number. Please check that it's correctly written.`,
    error_invalid_cbu: `Ouch! Enter a valid CBU`,
    error_invalid_collaborator_email: `¡Ouch! You cannot invite yourself.`,
    error_invalid_cuit: `Ouch! Enter a valid Cuit`,
    error_invalid_dni: `Ouch! Enter a valid DNI`,
    error_invalid_domain: `Ouch! Something went wrong. Please check that your Email is correct or try another.`,
    error_invalid_domain_to_register_account: `Ouch! Invalid Email to create an account.`,
    error_invalid_email_address: `Enter a valid Email`,
    error_invalid_expiry_date: `Enter a valid expiry date`,
    error_invalid_login: `The username or password is incorrect. Try again.`,
    error_invalid_name: `Ouch! Write only letters, not numbers.`,
    error_invalid_nit: `Ouch! Enter a valid NIT`,
    error_invalid_rfc: `Ouch! Enter a valid RFC`,
    error_invalid_size_file: `The file must be PDF, less than {maxSizeMB} MB`,
    error_invalid_type_pdf: `Selected file type is not a PDF`,
    error_is_withholding_agent: 'If you are withholding agent, you cannot use automatic debit',
    error_min_length: `Ouch! Enter a minimum characters count.`,
    error_min_length_2: `Ouch! Write at least two characters.`,
    error_password_character_length: `8 characters minimum`,
    error_password_digit: `One number`,
    error_password_format: `Remember that your passwords need 8 characters minimum, one letter and one number.`,
    error_password_invalid: `¡Ouch! Wrong password.`,
    error_password_letter: `One letter`,
    error_password_match: `Ouch! The password don't match.`,
    error_password_missing: `¡Ouch! Add a new password.`,
    error_password_safe: `Your Password is secure!`,
    error_phone_invalid: `Enter a valid phone`,
    error_phone_invalid_country: `Ouch! The country code is not valid.`,
    error_phone_required: `Enter a valid phone`,
    error_phone_too_long: `Ouch! The phone number is too long.`,
    error_phone_too_short: `Ouch! The phone number is too short.`,
    error_register_denied: `Hold on! You've reached the maximum accounts allowed.`,
    error_required_field: `Ouch! The field is empty.`,
    error_unexpected_MD: `Ouch! There seems to be a connection problem. Please try again in a few minutes.`,
    error_unexpected_register_MD: `Ouch! Something went wrong. Please try again later or [contact our Support team](${mailtoSupport}).`,
    error_upload_file: `Ouch! Something went wrong. Please try again.`,
    error_wrong_invitation_link: `Important announcement! You have no pending invitations.`,
    warning_ip_validation_notification: `You're trying to log in on a device Doppler doesn't recognize. Check your inbox! You'll find an Email with steps to follow.`,
    warning_ip_validation_notification_SignUp: `Doppler doesn't recognize this device. Try to log into this account first!`,
    warning_user_access_denied: `Ouch! Your login has been declined.`,
  },
};

export default messages_en;
